<template>
  <v-container class="down-top-padding" fluid>
    <base-breadcrumb :breadcrumbs="breadcrumbs" :icon="page.icon" :title="page.title"></base-breadcrumb>
    <base-card heading="Aplicações">
      <v-container class="my-2">
        <v-row>
          <v-spacer/>
          <v-col class="position-relative" cols="12">
            <v-text-field
              append-icon="mdi-magnify"
              background-color="transparent"
              clearable
              class="pb-4"
              dense
              hide-details
              label="Pesquisar aplicações"
              outlined
              @change="listApplications($event)"
            ></v-text-field>
            <div class="hint">Aperte ENTER para pesquisar</div>
          </v-col>
        </v-row>
      </v-container>
      <datatable-list
        v-if="schoolFetch"
        :displayActionItems="displayActionItems"
        :headers="headers"
        :items="items"
        :loading="!schoolFetch"
        :pagination="pagination"
        :showSelect="showSelect"
        :sortBy="sortBy"
        :tableUser="true"
        :text="text"
        @update="pagination = { ...pagination, ...$event}"
      ></datatable-list>
      <template v-else>
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="table-heading"
        ></v-skeleton-loader>
      </template>
    </base-card>
  </v-container>
</template>

<script>
import {
  handleError,
  isSuperAdmin,
  getSchoolIds,
  getCourseIds,
  hasOrganizationLevel
} from '@/utils/helpers'

export default {
  data: () => ({
    organizationId: '',
    page: {
      title: 'Aplicações'
    },
    schoolFetch: false,
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Aplicações',
        disabled: false,
        to: '/applications'
      }
    ],
    text: {
      searchLabel: 'Pesquisar Aplicações',
      emptyLabel: 'Nenhuma aplicação encontrada'
    },
    headers: [
      {
        text: 'Aplicação',
        value: 'name',
        align: 'start',
        sortable: true
      },
      { text: 'Período', value: 'dates', align: 'start', sortable: false },
      { text: 'Avaliação', value: 'exam', align: 'start', sortable: true },
      { text: 'Ações', value: 'actions', align: 'end', sortable: false }
    ],
    sortBy: '',
    showSelect: false,
    displayActionItems: false,
    items: [],
    domains: [],
    pagination: {
      page: 1,
      prev_page_url: false,
      next_page_url: false
    }
  }),
  watch: {
    async 'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.findApplication()
      }
    }
  },
  created () {
    this.organizationId = localStorage.getItem('ACTIVE_ORGANIZATION')
    this.findApplication()
  },
  methods: {
    handleError,
    getCourseIds,
    getSchoolIds,
    isSuperAdmin,
    hasOrganizationLevel,
    buildQuery () {
      return `?page=${this.pagination.page}`
    },
    findApplication () {
      const guid = this.$route.params.id
      return guid
        ? this.listApplicationsByExamID(guid)
        : this.listApplications()
    },
    formatApplications (applications) {
      const arr = []
      if (!applications.length) return arr
      applications.forEach(value => {
        if (value) {
          const obj = {
            ...value,
            name: value.name,
            url: `/exam/application/view/${value.exam_id}/${value.id}`,
            exam: value.exam_name,
            dates: {
              startsAt: value.starts_at,
              endsAt: value.ends_at
            },
            id: value.id,
            actions: [
              {
                title: 'Ver relatório de notas',
                url: `/reports/applications/view/${value.exam_id}/${value.id}`
              },
              {
                title: 'Ver alunos',
                url: `/exam/application/students/${value.exam_id}/${value.id}`
              }
            ]
          }
          arr.push(obj)
        }
      })
      return arr
    },
    listApplications (search = '') {
      this.schoolFetch = false
      const payload = {
        ...(this.getCourseIds().length ? { course_ids: this.getCourseIds() } : {}),
        ...(this.getSchoolIds().length ? { school_ids: this.getSchoolIds() } : {})
      }

      this.$axios.post(
        `/organizations/${this.organizationId}` +
        `/lists/exams-applications${this.buildQuery()}&search=${search}`, !this.hasOrganizationLevel() && !this.isSuperAdmin() ? payload : {})
        .then(response => response.data)
        .then(applications => {
          this.items = this.formatApplications(applications.items)

          this.pagination = {
            ...this.pagination,
            total: applications.pagination.total,
            page: applications.pagination.current_page
          }
        })
        .catch(error => this.handleError(error))
        .finally(() => (this.schoolFetch = true))
    },
    listApplicationsByExamID (guid) {
      this.schoolFetch = false
      this.$axios.get(
        `/organizations/${this.organizationId}` +
        `/exams/${guid}/applications`)
        .then(response => {
          this.items = this.formatApplications(response.data.items.map(item => {
            return {
              name: item.data.name,
              exam_name: item.data.exam.name,
              exam_id: item.data.exam.id,
              starts_at: item.data.starts_at,
              ends_at: item.data.ends_at,
              id: item.metadata.id
            }
          }))
          const pagination = response.data.pagination
          this.pagination = {
            ...this.pagination,
            total: pagination.total,
            page: pagination.current_page
          }
        })
        .catch(error => this.handleError(error))
        .finally(() => (this.schoolFetch = true))
    }
  }
}
</script>
